

.header1, .header2 { 
    position: relative;
    top: 50%;
    text-align: center;
    color: rgb(10, 9, 9);  
    background:rgb(208, 225, 238);
    font-family: cursive;
    font-size: 1.9rem;

    
 }

 .header3{ 
    position: relative;
    top: 50%;
    color: rgb(10, 9, 9);  
    background:white;
    font-family: cursive;
    font-size: 1.9rem;
    margin-left: 10%;
    margin-right: 10%;
 }

 .text1 {
  margin-left: 10%;
  margin-right: 10%;
 }

 .header-preis {
   position: relative;
   text-align: center;
   padding: 0;
   top: 50%;
   color: rgb(11, 26, 230);  
   background:white;
   font-family: cursive;
   font-size: 1.9rem;
   overflow:       hidden;  
 }

 .table-container .left-pic {
   position: relative;
   width: 200px;
   height: 200px;
   float: left;
   border-radius: 50%;
   margin-left: 10%;
   box-sizing: border-box;
   background-color: #DDD;
   border: 5px solid #cfd8dc;
 }
 .table-container .right-pic {
  position: relative;
  width: 200px;
  height: 200px;
  float: right;
  border-radius: 50%;
  margin-right: 10%;
  border-width: 10px 1px;
  box-sizing: border-box;
    background-color: #DDD;
    border: 5px solid #cfd8dc;
}



ul.leaders {
   font-size: x-large;
   margin-right: 10%;
   margin-left: 10%;
   overflow:       hidden;  
 }
 ul.leaders li {
   display:        table;
 }
 ul.leaders li span {
   display:        table-cell;
   font-family: cursive;
 }
 ul.leaders li span:first-child { /* TITLE */
   position:       relative;
   overflow:       hidden;       /* Don't go underneath the price */
 }
 ul.leaders li span:first-child:after { /* DASHES */
   content:        "";
   position:       absolute;
   bottom:         0.5em;        /* Set as you want */       
   margin-left:    0.5em;        /* Keep same for the next span's left padding */
   width:          100%;
   border-bottom:  1px dashed #000;
 }
 ul.leaders li span + span {     /* PRICE */
   text-align:     left;
   width:          1%;           /* Trick it */
   vertical-align: bottom;       /* Keep Price text bottom-aligned */
   padding-left:   0.5em;
   white-space: nowrap;       /* Uncomment if needed */
 }

 @media screen and (max-width: 960px) {
  .table-container .left-pic {
    position: relative;
    display: block;
    width: 300px;
    height: 300px;
    float: none;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    background-color: #DDD;
    border: 5px solid #cfd8dc;
  }
  .table-container .right-pic {
    position: relative;
    display: block;
    width: 300px;
    height: 300px;
    float: none;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    background-color: #DDD;
    border: 5px solid #cfd8dc;
 }
 
}