

.photo { 
  max-width: 100%;
  height: auto;
  flex: 1;
  filter: brightness(90%);
  transition: 1s;
}

.photo { 
    position: relative; 
    width: 100%; /* for IE 6 */
   
 }


 .header-home{
   font-size: x-large; 
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgb(255, 255, 255); 
    vertical-align: middle;
 }
  .text-home{ 
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(255, 255, 255); 
}

 
 h1 span { 
    color: white; 
    font: bold 24px/45px Helvetica, Sans-Serif; 
    letter-spacing: -1px;  
    background: rgb(0, 0, 0); /* fallback color */
    background: rgba(0, 0, 0, 0.4);
    padding: 10px; 
 }

 h3 span { 
    color: white; 
    font: bold 24px/45px Helvetica, Sans-Serif; 
    letter-spacing: -1px;  
    background: rgb(0, 0, 0); /* fallback color */
    background: rgba(0, 0, 0, 0.4);
    padding: 10px; 
 }



 /* Slideshow container */
 .slideshow-container {
   position: relative;
   margin: auto;
 }
 
 /* Hide the images by default */
 .mySlides {
   display: none;
 }

 .slidePhoto {
    width: 100%;
    flex: 1;
    filter: brightness(70%);
 }
 
 /* Next & previous buttons */
 .prev, .next {
   cursor: pointer;
   position: absolute;
   top: 50%;
   width: auto;
   margin-top: -22px;
   padding: 16px;
   color: white;
   font-weight: bold;
   font-size: 18px;
   transition: 0.6s ease;
   border-radius: 0 3px 3px 0;
   user-select: none;
 }
 
 /* Position the "next button" to the right */
 .next {
   right: 0;
   border-radius: 3px 0 0 3px;
 }
 
 /* On hover, add a black background color with a little bit see-through */
 .prev:hover, .next:hover {
   background-color: rgba(0,0,0,0.8);
 }
 
 /* Caption text */
 .text {
   color: #f2f2f2;
   font-size: 15px;
   padding: 8px 12px;
   position: absolute;
   bottom: 8px;
   width: 100%;
   text-align: center;
 }
 
 /* Number text (1/3 etc) */
 .numbertext {
   color: #f2f2f2;
   font-size: 12px;
   padding: 8px 12px;
   position: absolute;
   top: 0;
 }

 .dot-container {
     text-align: center;
 }
 
 /* The dots/bullets/indicators */
 .dot {
   cursor: pointer;
   height: 15px;
   width: 15px;
   margin: 0 2px;
   background-color: #bbb;
   border-radius: 50%;
   display: inline-block;
   transition: background-color 0.6s ease;
 }
 
 .active, .dot:hover {
   background-color: #717171;
 }
 
 /* Fading animation */
 .fade {
   -webkit-animation-name: fade;
   -webkit-animation-duration: 1.5s;
   animation-name: fade;
   animation-duration: 1.5s;
 }
 
 @-webkit-keyframes fade {
   from {opacity: .4}
   to {opacity: 1}
 }
 
 @keyframes fade {
   from {opacity: .4}
   to {opacity: 1}
 }

 @media screen and (max-width: 960px) {



 .header-home{
   font-size: x-large; 
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgb(255, 255, 255); 
    vertical-align: middle;
 }
  .text-home{ 
  position: absolute;
  top: 30%;
  left: 20%;
  transform: translate(-10%, -10%);
  color: rgb(255, 255, 255); 
  
}

.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 30%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

}